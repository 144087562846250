import {View, Sync} from 'MWC';
import tpl from '../templates/interactive.jsx';

export default class extends View {
    template = tpl;

    constructor(props) {
        super(props);
        this.state = {
            activeScreen: 'error',
            result: this.props.result
        };

        this._initMethods();
    }

    closeModal(event) {
        event ? event.stopPropagation() : this.props.closeModal();

        const target = event && event.target,
            classesToClose = ['form-wrapper', 'close-button'];

        if (target && classesToClose.some(className => target.classList.contains(className))) {
            this.props.closeModal();
        }
    }

    handleEscape(event) {
        if (event.keyCode === 27) {
            this.closeModal();
        }
    }

    _initMethods() {
        this.closeModal = this.closeModal.bind(this);
        this.handleEscape = this.handleEscape.bind(this);
    }

    componentDidMount() {
        window.addEventListener('keydown', this.handleEscape);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleEscape);
    }

    static displayName = 'Notification.Modal.';
}
