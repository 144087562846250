import React from 'react';
import "../less";
import close_img from '../img/x.svg';
import heart_img from '../img/heart_img.png';

export default function template() {
    const isMobile = this.isViewportMobile();
    const step = this.state.step;
    const feedback = this.state.feedback;



    return (
      <div className='modal-wrapp'>
        <div className='feedback-modal-overlay'>
          <div className="feedback-modal">
            <div className="feedback-modal-head">
              <div className='feedback-modal-head-title'>{this.l10n('REVIEW_MODAL_HEAD_TEXT')}</div>
              {isMobile ? '' :
                <button className="modal-close" id={`close_${step} btn`} onClick={() => this.props.closeModal()}>
                  <img src={close_img} alt="close_img" />
                </button>
              }
            </div>

            {step === 1 && (
              <>
                <div className='feedback-modal-title' dangerouslySetInnerHTML={{__html: this.l10n('REVIEW_MODAL_TITLE_1')}}></div>
                <div className='feedback-modal-text'>{this.l10n('REVIEW_MODAL_TEXT')}</div>
                <div className="feedback-modal-buttons">
                  <button className='black-btn' id='step_one yes_btn' onClick={() => this.handleNext(3)}>{this.l10n('REVIEW_MODAL_BTN_TEXT_1')}</button>
                  <button className='black-btn white' id='step_one no_btn' onClick={() => this.handleNext(2)}>{this.l10n('REVIEW_MODAL_BTN_TEXT_2')}</button>
                </div>
              </>
            )}
            {step === 2 && (
              <>
                <div className='feedback-modal-title' dangerouslySetInnerHTML={{__html: this.l10n('REVIEW_MODAL_TITLE_2')}}></div>
                <textarea
                  value={feedback}
                  onChange={(e) => this.setState({feedback: e.target.value})}
                  maxLength={450}
                />
                <button
                  onClick={() => this.handleFeedbackSubmit()}
                  disabled={!feedback.trim()}
                  className='black-btn'
                  id='step_two send_btn'
                >
                  {this.l10n('REVIEW_MODAL_BTN_TEXT_3')}
                </button>
              </>
            )}
            {step === 3 && (
              <>
                <div className='feedback-modal-title'>
                  {this.l10n('REVIEW_MODAL_TITLE_3')}
                  <img src={heart_img} alt="heart_img" />
                </div>
                <button id='step_three thanks_btn' className='black-btn white last' onClick={() => this.props.closeModal()}>{this.l10n('REVIEW_MODAL_BTN_TEXT_4')}</button>
              </>
            )}
          </div>
        </div>
      </div>
    )
}
