import dropDownIcon from './assets/img/dropDown/dd_triangle_down.svg';
import dropDownIconActive from './assets/img/dropDown/dd_triangle_down_hover.svg';

const normalShadow = '0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12)',
    largeShadow = '0 12px 12px rgba(0, 0, 0, 0.24), 0 20px 40px rgba(0, 0, 0, 0.18)';

const variables = {
    // ссылки, выделенный текст, активные состояния
    tAccent: '#64B22C',
    tWarning: '#F5A623',
    // цвет ошибки
    tError: '#FF635F',
    // цвет заполненного неактивного текста
    tFilled: '#1a1918',
    // цвет label
    tLabel: '#868686',
    // цвет текста в активном состоянии
    tActive: 'rgba(0, 0, 0, 1)',
    // второстепенный цвет текста, цвет неактивных иконок
    tInactive: 'rgba(0, 0, 0, 0.54)',
    // цвет подсказок
    tHint: 'rgba(0, 0, 0, 0.38)',
    // placeholder
    tPlaceholder: 'rgba(0, 0, 0, 0.24)',
    // disabled
    tDisabled: 'rgba(0, 0, 0, 0.24)',
    tInversion: '#FFFFFF',
    tSecondaryAccent: '#4478DE',
    tSecondaryAccentDark: '#217cb5',
    tSecondaryAccentLight: '#97d4ff',
    tPrimaryInversion: '#FFF',

    // фирменный зеленый
    elAccent: '#64B22C',
    // Темное состояние основного цвета для hover эффектов на кнопках, Цвет текстовых ссылок и выделенного текста
    elAccentDark: '#69af25',
    // Светлое состояние основного цвета для pass эффектов на кнопках. Hover цвет для текстовых ссылок
    elAccentLight: '#aee571',
    // фирменный второстепенный, активные состояния
    elSecondaryAccent: '#4478DE',
    elSecondaryAccentDark: '#217cb5',
    elSecondaryAccentLight: '#97d4ff',
    // фирменный красный
    elError: '#FF635F',
    // фон для ошибки
    elErrorLight: '#ffe6e5',
    // фирменный второстепенный
    elWarning: '#F5A623',
    // основной фон
    elPrimary: '#FFFFFF',
    // hover для светлых фонов
    elPrimaryLight: 'rgba(0, 0, 0, 0.02)',
    // самый светлый фон
    elLightest: 'rgba(0, 0, 0, 0.04)',
    // светлый фон
    elLight: 'rgba(0, 0, 0, 0.08)',
    // темный фон
    elDark: 'rgba(0, 0, 0, 0.12)',
    // самый темный фон
    elDarkest: 'rgba(0, 0, 0, 0.24)',
    elDivider: 'rgba(0, 0, 0, 0.12)',
    // черный фон (для темной темы)
    elBlack: 'rgba(0, 0, 0, 0.87)',

    // темный фон
    elSecondaryLight: 'rgba(0, 0, 0, 0.38)',
    elSecondary: 'rgba(0, 0, 0, 0.54)',

    // тени
    elShadow: 'rgba(0, 0, 0, 0.16)',
    elShadowMain: '0 8px 8px 0 rgba(0, 0, 0, 0.24)',
    elShadowLight: 'rgba(0, 0, 0, 0.12)',

    // text
    fontFamily: 'Roboto',

    // font weight
    twNormal: 400,
    // light
    twLight: 300,
    // bold
    twBold: 700,
    // semi-bold
    twSemi: 600,

    tsXXSmall: '10px',
    tsXSmall: '12px',
    tsSmall: '14px',
    tsNormal: '16px',
    tsXNormal: '18px',
    tsLarge: '20px',
    tsXLarge: '22px',
    tsXXLarge: '24px',

    tlXXSmall: '10px',
    tlXSmall: '20px',
    tlSmall: '22px',
    tlNormal: '24px',
    tlLarge: '28px',
    tlXLarge: '30px',
    tlXXLarge: '32px',

    letterSpacing: '-0.014em',

    // gutter width
    gutter: 8,

    transitionDuration: '450ms',
    transitionDurationNumber: 450,

    transitionFunction: 'cubic-bezier(0.23, 1, 0.32, 1)',

    transitionDelay: '0ms',

    mainTransition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',

    zMaximal: 1001,
    zOverlay: 999
};

export const palette = variables;

export default {
    palette: variables,
    ...variables,

    button: {
        fontWeight: 400,
        borderRadius: '100px',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '32px',
        paddingRight: '32px',
        boxShadow: normalShadow,
        hoverBoxShadow: normalShadow,
        focusBoxShadow: normalShadow,
        activeBoxShadow: largeShadow
    },
    input: {
        labelColor: '#868686',
        idleBorderColor: '#D8D8D8',
        lineHeight: 1.25
    },
    dropDown: {
        lineHeight: 1.25,
        labelColor: '#868686',
        appearanceImage: `url("${dropDownIcon}")`,
        appearanceImageHover: `url("${dropDownIconActive}")`,
        listBoxShadow: normalShadow
    },
    toggler: {
        lineHeight: 1.25,
        labelColor: '#868686'
    },
    plainText: {
        lineHeight: 1.25,
        labelColor: '#868686'
    },
    link: {
        inTextTextDecoration: 'underline',
        inTextHoverTextDecoration: 'none',
        color: '#64B22C',
        hoverColor: '#64B22C'
    },
    control: {
        marginBottom: '32px'
    }
};
