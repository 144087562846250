import {View} from 'MWC';
import tpl from '../templates/interactive.jsx';
import {apiback} from '../../../config';

export default class extends View {
    template = tpl;

    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            feedback: '',
            closeTimer: null, // Хранение таймера,
            currentPath: location.pathname
        };

        this.handleNext = this.handleNext.bind(this);
        this.handleFeedbackSubmit = this.handleFeedbackSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.resetTimer = this.resetTimer.bind(this); // Сброс таймера
    }

    componentWillMount() {
        // Запуск таймера при монтировании
        const timer = setTimeout(() => {
            this.closeModal();
        }, 30000);

        this.setState({closeTimer: timer});
    }

    componentDidUpdate() {
        // Проверяем, изменился ли маршрут
        if (location.pathname !== this.state.currentPath) {
            this.props.closeModal(true); // Закрываем модальное окно
        }
    }

    componentWillUnmount() {
        // Очистка таймера при размонтировании
        if (this.state.closeTimer) {
            clearTimeout(this.state.closeTimer);
        }
    }

    resetTimer() {
        // Сброс существующего таймера
        if (this.state.closeTimer) {
            clearTimeout(this.state.closeTimer);
        }
    }

    handleNext(index) {
        this.setState({step: index}, this.resetTimer); // Сброс таймера при смене шага
    }

    closeModal() {
        this.props.closeModal();
        if (this.state.closeTimer) {
            clearTimeout(this.state.closeTimer); // Очистка таймера при закрытии модалки
        }
    }

    async handleFeedbackSubmit() {
        try {
            const payload = {
                text: this.state.feedback,
                url: window.location.href // Текущий URL страницы
            };
            const url = apiback('API_FEEDBACK');

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                this.handleNext(3); // Переход на шаг 3
            } else {
                console.error('Failed to submit feedback:', response.statusText);
            }
        } catch (error) {
            console.error('Error submitting feedback:', error);
        }
        this.handleNext(3); // Переход на шаг 3 с автоматическим сбросом таймера
    }

    static displayName = 'Notification.Modal.';
}
