import React, { Suspense, lazy } from 'react';
import {Router, Route, Switch, Redirect} from 'react-router-dom';
import '../less/index.less';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import theme from '../../theme';
import {ThemeProvider, Preloader} from 'MWC';
import { HelmetProvider } from 'react-helmet-async';
const Header = lazy(() => import('../../auxiliary-components/Header'));
const Footer = lazy(() => import('../../auxiliary-components/Footer'));
const Home = lazy(() => import('../../pages/Home'));
const Cards = lazy(() => import('../../pages/Cards'));
const Business = lazy(() => import('../../pages/Business'));
const IBAN = lazy(() => import('../../pages/Business/views/iban.c'));
const Credit = lazy(() => import('../../pages/Credit'));
const Deposit = lazy(() => import('../../pages/Deposit'));
const About = lazy(() => import('../../pages/About'));
const Payments = lazy(() => import('../../pages/Services/components/Payments'));
const Express = lazy(() => import('../../pages/Express'));
const Career = lazy(() => import('../../pages/Career'));
const Branches = lazy(() => import('../../pages/Branches'));
const Contacts = lazy(() => import('../../pages/Contacts'));
const Terms = lazy(() => import('../../pages/Terms'));
const Tso = lazy(() => import('../../pages/TSO'));
const Fgvo = lazy(() => import('../../pages/Fgvo'));
const Transfers = lazy(() => import('../../pages/Services/components/Transfers'));
const Security = lazy(() => import('../../pages/Security'));
const Safety = lazy(() => import('../../pages/Safety'));
const Search = lazy(() => import('../../pages/Search'));
const Services = lazy(() => import('../../pages/Services'));
const Archive = lazy(() => import('../../pages/Archive'));
const Documents = lazy(() => import('../../pages/Documents'));
const NotFound = lazy(() => import('../../pages/NotFound'));
const DynamicInfo = lazy(() => import('../../pages/DynamicInfo'));
const CreditProtection = lazy(() => import('../../pages/CreditProtection'));
const AntiRus = lazy(() => import('../../pages/AntiRus'));
const AtbMarket = lazy(() => import('../../pages/AtbMarket'));
const CloseA24Web = lazy(() => import('../../pages/CloseA24web'));
const ForPartners = lazy(() => import('../../pages/ForPartners'));
const ThankYouPage = lazy(() => import('../../pages/ThankyouPage'));
import NotificationModal from '../../auxiliary-components/NotificationModal';
/** todo remove after create news **/
const News = lazy(() => import('../../pages/News'));
// import News from '../../pages/NewsOld';
const Red = lazy(() => import('../../pages/Redirect'));
const GreenCard = lazy(() => import('../../pages/GreenCard'));
const FaceId = lazy(() => import('../../pages/FaceId'));
const SpecialOffers = lazy(() => import('../../pages/SpecialOffers'));
const Leasing = lazy(() => import('../../pages/Services/components/Leasing'));
const MobStoreLink = lazy(() => import('../../auxiliary-components/MobStoreLink'));
const ScrollFAQ = lazy(() => import('../../auxiliary-components/shared/ScrollFAQ'));
const Gadgetomania = lazy(() => import('../../pages/Gadgetomania'));
const Ltd = lazy(() => import('../../pages/Ltd'));
const PayInInstallment = lazy(() => import('../../pages/PayInInstallments'));
// import TarnsferByPhone from '../../TarnsferByPhone';
const AppleSummer = lazy(() => import('../../pages/AppleSummer'));
const AlwaysTouchOffers = lazy(() => import('../../pages/SpecialOffers/AlwaysTouchOffers'));
const WorldElectronicWallets = lazy(() => import('../../pages/SpecialOffers/WorldElectronicWallets'));
const ContactLess = lazy(() => import('../../pages/SpecialOffers/ContactLess'));
const MobileMenuQr = lazy(() => import('../../pages/MobileMenuQr'));
const ShareLink = lazy(() => import('../../auxiliary-components/shared/ShareLink'));
const DownloadsPage = lazy(() => import('../../pages/DownloadsPage'));
const ProjectSupport = lazy(() => import('../../pages/Cards/ProjectSupport'));
const BusinessCard = lazy(() => import('../../pages/BusinessCard'));
const SafeFiles = lazy(() => import('../../pages/SafeFiles'));
const WeWorkModal = lazy(() => import('../../auxiliary-components/WeWorkModal'));
const ScrollButton = lazy(() => import('../../auxiliary-components/shared/ScrollButton'));
const MasterCardSpecialOffer = lazy(() => import('../../pages/MasterCardSpecialOffer/views/masterCardSpecialOffer.c'));
const Policy = lazy(() => import('../../pages/Policy'));
const AboutNewGeneral = lazy(() => import('../../pages/About/components/general'));
const DigitalPromoMC = lazy(() => import('../../pages/SpecialOffers/DigitalPromoMC'));
const GoodDeedsMC = lazy(() => import('../../pages/SpecialOffers/GoodDeedsMC'));
const OkkoPromoMC = lazy(() => import('../../pages/SpecialOffers/OkkoPromoMC'));
const UnbrokenOffer = lazy(() => import('../../pages/SpecialOffers/UnbrokenOffer'));
const ATBPromo = lazy(() => import('../../pages/SpecialOffers/AtbPromo'));
const MillionAtb = lazy(() => import('../../pages/SpecialOffers/MillionAtb'));
const VisaIphone = lazy(() => import('../../pages/SpecialOffers/VisaIPhone'));
const StepToVictory = lazy(() => import('../../pages/SpecialOffers/StepToVictory'));
const AssembledUa = lazy(() => import('../../pages/SpecialOffers/AssembledUa'));
const PayoneerIphone = lazy(() => import('../../pages/SpecialOffers/PayoneerIphone'));
const SorryPage = lazy(() => import('../../pages/SorryPage'));
const AuroraPromo = lazy(() => import('../../pages/SpecialOffers/AuroraPromo'));
const HalfMillionDraw = lazy(() => import('../../pages/SpecialOffers/HalfMillionDraw'));
const ShopingAtb = lazy(() => import('../../pages/SpecialOffers/ShopingAtb'));
const KidsDrawModule = lazy(() => import('../../pages/SpecialOffers/KidsDraw'));
const EnergyIndependence = lazy(() => import('../../pages/SpecialOffers/EnergyIndependence'));
const ExpressEnergy = lazy(() => import('../../pages/SpecialOffers/ExpressEnergy'));
const SepaFop = lazy(() => import('../../pages/SpecialOffers/SepaFop'));
const EnergyCredit = lazy(() => import('../../pages/Business/components/EnergyCredit'));
const InsuranceTour = lazy(() => import('../../pages/InsuranceTour'))

import ScrollToTop from "../ScrollToTop";
import ReviewModal from "../../auxiliary-components/ReviewModal";

export default function template() {
    const {lang, single} = this.state,
        {viewport} = this.props,
        header = !single ? <Header lang={lang} viewport={viewport} isBlack={this.state.isBlackTheme} /> : '',
        footer = !single ? <Footer lang={lang} viewport={viewport} /> : '',
        mobile = !single ? <MobStoreLink lang={lang} viewport={viewport} /> : '',
        scrollButton = !single ? <ScrollButton /> : '',
        modal = this.state.showModal ?
          <WeWorkModal closeModal={this.closeModal}/> : '',
      reviewModal = this.state.showReviewModal ? <ReviewModal closeModal={this.closeReviewModal} /> : '';

    return (
      <HelmetProvider>
        <ThemeProvider theme={theme}>
            <Router history={this.history}>
                <Suspense fallback={<div style={{ marginTop: 50 }} className="module-app-content"><Preloader/></div>}>
                    <ScrollToTop />
                    {/*{mobile}*/}
                    {header}
                    <section className={`module-app-content ${this.state.isBlackTheme ? 'black' : ''}`}>
                        {modal}
                        {!single ? <ScrollFAQ isApple={this.state.isApple} /> : ''}
                        <Switch>
                            <Route
                                exact
                                path='/'
                                render={(props) => (
                                    <Home {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/offrus'
                                render={(props) => (
                                    <AntiRus {...props} lang={lang} viewport={viewport} />
                                )}
                            />

                            <Route
                                path='/a24web-close'
                                render={(props) => (
                                    <CloseA24Web {...props} lang={lang} viewport={viewport} />
                                )}
                            />

                            <Route
                              path='/forpartners'
                              render={(props) => (
                                <ForPartners {...props} lang={lang} viewport={viewport} />
                              )}
                            />

                            <Route
                                path='/atb-market'
                                render={(props) => (
                                    <AtbMarket {...props} lang={lang} viewport={viewport} />
                                )}
                            />

                            <Route
                              path='/atb-market1'
                              render={(props) => (
                                <AtbMarket {...props} simplePage={true} lang={lang} viewport={viewport} />
                              )}
                            />

                            <Route
                                exact
                                path='/thankyou/a24'
                                render={(props) => (
                                    <ThankYouPage {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/cards/:type?/:action?'
                                render={(props) => (
                                    <Cards {...props} lang={lang} viewport={viewport} setBlackTheme={this.setBlackTheme}/>
                                )}
                            />
                            <Route
                                path='/business/iban'
                                render={(props) => (
                                    <IBAN {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/business/:type?/:action?'
                                render={(props) => (
                                    <Business {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/https://ab.a-bank.com.ua/'
                                render={(props) => (
                                    <meta httpEquiv="Refresh" content="0;URL=https://ab.a-bank.com.ua/"/>
                                )}
                            />
                            <Route
                                path='/credit/:type?'
                                render={(props) => (
                                    <Credit {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/deposits'
                                render={(props) => (
                                    <Deposit {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/about/:category'
                                render={(props) => (
                                  <AboutNewGeneral {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/payments'
                                render={(props) => (
                                    <Payments {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/a-express'
                                render={(props) => <Redirect to='/business/a-express' />}
                            />
                            <Route
                                path='/express'
                                render={(props) => (
                                    <Express {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/career/:type?/:id?'
                                render={(props) => (
                                    <Career {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/contacts'
                                render={(props) => (
                                    <Contacts isApple={this.state.isApple} {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/terms'
                                render={(props) => (
                                    <Terms {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                              path='/self_service_terminal'
                              render={(props) => (
                                <Tso {...props} lang={lang} viewport={viewport} />
                              )}
                            />
                            <Route
                                path='/fgvo'
                                render={(props) => (
                                    <Fgvo {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/branches'
                                render={(props) => (
                                    <Branches {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/transfers/:type?'
                                render={(props) => (
                                    <Transfers {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/security'
                                render={(props) => (
                                    <Security {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                              path='/search'
                              render={(props) => (
                                <Search {...props} lang={lang} viewport={viewport} />
                              )}
                            />
                            <Route
                                path='/safety'
                                render={(props) => (
                                    <Safety {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/services/:type?/:secondType?/:lastPage?'
                                render={(props) => (
                                    <Services {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/archive'
                                render={(props) => (
                                    <Archive {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/special_offers/:type?'
                                render={(props) => (
                                    <SpecialOffers {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/mobile-menu-qr/:type?'
                                render={(props) => (
                                    <MobileMenuQr {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/news/:id?'
                                render={(props) => (
                                    <News {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/check-doc'
                                render={(props) => (
                                    <Documents {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/message/141118'
                                render={(props) => (
                                    <DynamicInfo {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/r'
                                render={(props) => (
                                    <Red {...props} lang={lang} viewport={viewport} viber />
                                )}
                            />
                            <Route
                                path='/t/:action?'
                                render={(props) => (
                                    <Red {...props} lang={lang} viewport={viewport} referral />
                                )}
                            />
                            <Route
                                path='/green/:section?'
                                render={(props) => (
                                    <GreenCard {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/faceid/:type'
                                render={(props) => (
                                    <FaceId {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                path='/auto-leasing'
                                render={(props) => (
                                    <Leasing {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                exact
                                path='/gadgetomania'
                                render={(props) => (
                                    <Gadgetomania {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                exact
                                path='/DE'
                                render={(props) => (
                                    <Ltd {...props} lang={lang} viewport={viewport} ltd={'DE'} />
                                )}
                            />
                            <Route
                                exact
                                path='/PR'
                                render={(props) => (
                                    <Ltd {...props} lang={lang} viewport={viewport} ltd={'PR'} />
                                )}
                            />
                            <Route
                                exact
                                path='/DF'
                                render={(props) => (
                                    <Ltd {...props} lang={lang} viewport={viewport} ltd={'DF'} />
                                )}
                            />
                            <Route
                                exact
                                path='/KE'
                                render={(props) => (
                                    <Ltd {...props} lang={lang} viewport={viewport} ltd={'KE'} />
                                )}
                            />
                            <Route
                                exact
                                path='/AK'
                                render={(props) => (
                                    <Ltd {...props} lang={lang} viewport={viewport} ltd={'AK'} />
                                )}
                            />
                            <Route
                                exact
                                path='/pay-in-installment'
                                render={(props) => (
                                    <PayInInstallment {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            {/* <Route
                                exact
                                path="/transfers/phone"
                                render={props => (
                                    <TarnsferByPhone {...props} lang={lang} viewport={viewport} />
                                )}
                            /> */}
                            <Route
                                exact
                                path='/apple-summer'
                                render={(props) => (
                                    <AppleSummer {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                exact
                                path='/always-touch'
                                render={(props) => (
                                    <AlwaysTouchOffers {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                exact
                                path='/world-electronic-wallets'
                                render={(props) => (
                                    <WorldElectronicWallets
                                        {...props}
                                        lang={lang}
                                        viewport={viewport}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path='/contact-less'
                                render={(props) => (
                                    <ContactLess {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                exact
                                path='/mobile-menu-qr'
                                render={(props) => (
                                    <MobileMenuQr {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                exact
                                path='/business-card/:section?'
                                render={(props) => (
                                    <BusinessCard {...props} lang={lang} viewport={viewport} />
                                )}
                            />

                            <Route
                                exact
                                path='/downloads-page'
                                render={(props) => (
                                    <DownloadsPage {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                exact
                                path='/project-support'
                                render={(props) => (
                                    <ProjectSupport {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                exact
                                path='/sharelink'
                                render={(props) => (
                                    <ShareLink {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                exact
                                path='/safe-files/:type?'
                                render={(props) => (
                                    <SafeFiles {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                exact
                                path='/credit-protection'
                                render={(props) => (
                                    <CreditProtection {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                exact
                                path='/policy'
                                render={(props) => (
                                    <Policy {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                                exact
                                path='/insurance_tour'
                                render={(props) => (
                                    <InsuranceTour {...props} lang={lang} viewport={viewport} />
                                )}
                            />

                            <Route
                              exact
                              path='/sorry-page'
                              render={(props) => (
                                <SorryPage {...props} lang={lang} viewport={viewport} />
                              )}
                            />

                            <Route
                              exact
                              path='/digitalpromomc'
                              render={(props) => (
                                <DigitalPromoMC {...props} lang={lang} viewport={viewport} />
                              )}
                            />
                            <Route
                              exact
                              path='/offerpayoneer'
                              render={(props) => (
                                <PayoneerIphone {...props} lang={lang} viewport={viewport} />
                              )}
                            />
                            <Route
                                exact
                                path='/communication-without-borders'
                                render={(props) => (
                                    <MasterCardSpecialOffer {...props} lang={lang} viewport={viewport} />
                                )}
                            />
                            <Route
                              exact
                              path='/good-deeds-MC'
                              render={(props) => (
                                <GoodDeedsMC {...props} lang={lang} viewport={viewport} />
                              )}
                            />
                            <Route
                              exact
                              path='/millionatb'
                              render={(props) => (
                                <MillionAtb {...props} lang={lang} viewport={viewport} />
                              )}
                            />
                            <Route
                              exact
                              path='/visaiphone'
                              render={(props) => (
                                <VisaIphone {...props} lang={lang} viewport={viewport} />
                              )}
                            />
                            <Route
                              exact
                              path='/step_to_victory'
                              render={(props) => (
                                <StepToVictory {...props} lang={lang} viewport={viewport} />
                              )}
                            />
                            <Route
                              exact
                              path='/assembled_ua'
                              render={(props) => (
                                <AssembledUa {...props} lang={lang} viewport={viewport} />
                              )}
                            />
                            <Route
                              exact
                              path='/unbroken-donation'
                              render={(props) => (
                                <UnbrokenOffer {...props} lang={lang} viewport={viewport} />
                              )}
                            />

                            <Route
                              exact
                              path='/atb-promo'
                              render={(props) => (
                                <ATBPromo {...props} lang={lang} viewport={viewport} />
                              )}
                            />

                            <Route
                              exact
                              path='/avrorapromo'
                              render={(props) => (
                                <AuroraPromo {...props} lang={lang} viewport={viewport} />
                              )}
                            />

                            <Route
                              exact
                              path='/500000_draw'
                              render={(props) => (
                                <HalfMillionDraw {...props} lang={lang} viewport={viewport} />
                              )}
                            />

                            <Route
                              exact
                              path='/shopping_gifts_atb'
                              render={(props) => (
                                <ShopingAtb {...props} lang={lang} viewport={viewport} />
                              )}
                            />

                            <Route
                              exact
                              path='/kids_draw'
                              render={(props) => (
                                <KidsDrawModule {...props} lang={lang} viewport={viewport} />
                              )}
                            />

                            <Route
                              exact
                              path='/back_to_school'
                              render={(props) => (
                                <KidsDrawModule secondOffer {...props} lang={lang} viewport={viewport} />
                              )}
                            />

                            <Route
                              exact
                              path='/energy_independence'
                              render={(props) => (
                                <EnergyIndependence {...props} lang={lang} viewport={viewport} />
                              )}
                            />

                            <Route
                              exact
                              path='/express_energy'
                              render={(props) => (
                                <ExpressEnergy {...props} lang={lang} viewport={viewport} />
                              )}
                            />

                            <Route
                              exact
                              path='/sepa_fop'
                              render={(props) => (
                                <SepaFop {...props} lang={lang} viewport={viewport} />
                              )}
                            />


                            <Route
                              exact
                              path='/promomcokko'
                              render={(props) => (
                                <OkkoPromoMC {...props} year={'2023'} lang={lang} viewport={viewport} />
                              )}
                            />

                            <Route
                              exact
                              path='/promomcokko2024'
                              render={(props) => (
                                <OkkoPromoMC {...props} year={'2024'} lang={lang} viewport={viewport} />
                              )}
                            />

                            <Route
                              exact
                              path='/promomcokko24'
                              render={(props) => (
                                <OkkoPromoMC {...props} year={'2024_2'} lang={lang} viewport={viewport} />
                              )}
                            />

                            <Route
                              exact
                              path='/energy_credit'
                              render={(props) => (
                                <EnergyCredit {...props} lang={lang} viewport={viewport} />
                              )}
                            />

                            <Route
                                render={(props) => (
                                    <NotFound {...props} lang={lang} viewport={viewport} />
                                )}
                            />


                        </Switch>
                        {reviewModal}
                    </section>
                    {footer}
                </Suspense>
            </Router>
        </ThemeProvider>
      </HelmetProvider>
    );
}
