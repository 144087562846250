import React from 'react';
import "../less";

export default function template() {
    return (
        <div className="form-wrapper" onClick={this.closeModal}>
            <div className="form-container">
                <div className="close-button" />
                {this.props.children}
            </div>
        </div>
    )
}
