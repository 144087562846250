import defaultConf from './config.json';
import {environment} from './environment/environment';

let _config = defaultConf || {};

export function set(name = '', value = null) {
    if (name && typeof name === 'object') {
        _config = {..._config, ...name};
    } else if (name && typeof name === 'string') {
        _config[name] = value;
    }

    return _config;
}

export function get(name = '') {
    if (typeof _config[name] !== 'undefined') {
        return _config[name];
    } else {
        return null;
    }
}

export function api(name = '') {
    if (_config[name]) {
        return environment.url + (_config.API_BASE || '/') + _config[name];
    } else {
        return '/';
    }
}

export function apiback(name = '') {
    if (_config[name]) {
        return environment.url + (_config.API_BASE_BACKEND || '/') + _config[name];
    } else {
        return '/';
    }
}

export function proxy(name = '') {
    if (_config[name]) {
        return (_config.API_PROXY || '/') + _config[name];
    } else {
        return '/';
    }
}
