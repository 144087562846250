import React from 'react';
import {View} from 'MWC';
import EventListener from 'react-event-listener';

const MIN_MOBILE = 0,
    MAX_MOBILE = 760, // 768 tablet S
    MIN_TABLET = 761,
    MAX_TABLET = 1270; // 1280 laptop S

class Responsive extends View {
    constructor(props) {
        super(props);

        this.state = {
            viewport: this._getViewportType()
        };

        return this;
    }

    render() {
        if (typeof this.ComponentToWrap === 'function') {
            const Component = this.ComponentToWrap;

            return (
                <React.Fragment>
                    <EventListener target={window} onResize={this._handleWindowResize} />
                    <Component {...this.props} {...this.state} />
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    _handleWindowResize = event => {
        const viewport = this._getViewportType();

        if (viewport !== this.state.viewport) {
            this.setState({viewport});
        }

        return this;
    };

    _getViewportType() {
        const width = document.body.offsetWidth;

        if (width >= MIN_MOBILE && width <= MAX_MOBILE) {
            return this.viewportTypes.MOBILE;
        } else if (width >= MIN_TABLET && width <= MAX_TABLET) {
            return this.viewportTypes.TABLET;
        } else {
            return this.viewportTypes.DESKTOP;
        }
    }
}

const connectFabric = Component => {
    return class extends Responsive {
        ComponentToWrap = Component;

        static displayName = `ConnectResponsive(${Component.displayName || ''})`;
    };
};

const connectResponsive = function(Component) {
    return connectFabric(Component);
};

export default connectResponsive;
