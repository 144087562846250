import {environment as prod} from './environment.production';
import {environment as dev} from './environment.development';

let env = prod;
const type = process.env.NODE_ENV;

if (type === 'production') {
    env = prod;
}

if (type === 'development') {
    env = dev;
}

export const environment = env;
